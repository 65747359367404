import { useNavigate } from 'react-router-dom';

import { ONE } from '@/constants';
import { Avatar, AvatarGroup, Button, Typography } from '@/ui';

import type { ActionProps } from '.';
import type { FC } from 'react';

const Mobile: FC<ActionProps> = ({ coaches }) => {
  const navigate = useNavigate();
  const onCoaching = () => {
    navigate('./sessions/upcoming');
  };

  if (!coaches.length) return null;

  return (
    <Button
      className="group flex items-center gap-2 rounded-full px-4 py-2 transition ease-in-out hover:bg-cement-600/30 md:ml-6 lg:hidden"
      onClick={onCoaching}
    >
      <AvatarGroup max={3}>
        {coaches.map(({ id, firstName, avatarUrl = '' }) => (
          <Avatar
            key={`coach-mobile-${id}`}
            className="border-none"
            firstName={firstName}
            src={avatarUrl}
          />
        ))}
      </AvatarGroup>
      <div className="flex flex-col text-left text-white">
        <Typography variant="caption">
          Your coach{coaches.length > ONE ? 'es' : ''}
        </Typography>
        <Typography
          className="max-w-xs cursor-pointer overflow-x-hidden whitespace-nowrap"
          variant="subtitle1"
        >
          {coaches.map(({ firstName }) => firstName).join(' & ')}
        </Typography>
      </div>
    </Button>
  );
};

export default Mobile;
