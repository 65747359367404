import { useFlagBag } from '@happykit/flags/context';
import clsx from 'clsx';
import { useEffect, useMemo, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import { TWO, ZERO } from '@/constants';
import { useCurrentUser } from '@/hooks';

import type { FeatureFlags } from '@/types';

import Header from '@/components/Onboarding/Header';
import { OnboardingContext } from '@/components/Onboarding/context';

// Steps in order
const STEPS = ['about', 'welcome', 'match'];
const WHITELIST_STEPS = ['membership-select', 'membership-paid'];

const Onboarding = () => {
  const { flags } = useFlagBag<FeatureFlags>();
  const { user, isUserLoading } = useCurrentUser();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const [currentStep, setCurrentStep] = useState(ZERO);

  useEffect(() => {
    const subPath = pathname.replace('/onboarding/', '');
    const isWhitelistPath = WHITELIST_STEPS.includes(subPath);
    const index = isWhitelistPath ? TWO : STEPS.indexOf(subPath);

    setCurrentStep(index >= ZERO ? index : ZERO);
  }, [pathname]);

  useEffect(() => {
    if (flags && !flags.new_onboarding) {
      navigate('/get-started');
    }
  }, [flags]);

  const nextStep = (skip = 1, shouldReplace = false) => {
    const nextPath = STEPS[currentStep + skip] ?? '/m';

    navigate(nextPath, { replace: shouldReplace });
  };

  const context = useMemo(
    () => ({
      currentStep,
      nextStep,
    }),
    // we don't need to update when nextStep is redefined, because all the
    // variables in the closure will trigger an update
    [currentStep],
  );

  const isMembershipSelect = pathname.startsWith(
    '/onboarding/membership-select',
  );

  // Checking for userId to avoid flickers on refetch
  // TODO: Remove once we have fixed the issue with refetch on focus
  if (isUserLoading && !user.id) return null;

  return (
    <main
      className={clsx(
        'flex h-screen flex-1 flex-col overflow-auto p-5 px-6 md:px-14 md:py-9',
        {
          'bg-sunny-100': !isMembershipSelect,
          'bg-sunny-200': isMembershipSelect,
        },
      )}
    >
      <OnboardingContext.Provider value={context}>
        <Header />
        <Outlet />
      </OnboardingContext.Provider>
    </main>
  );
};

export default Onboarding;
