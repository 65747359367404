import { useContext, useEffect } from 'react';
import { InlineWidget, useCalendlyEventListener } from 'react-calendly';

import { notifySlack, updateUserProfile } from '@/api';
import {
  MILLISECONDS_IN_MINUTE,
  SCHEDULE_SESSION_URL,
  ZERO,
} from '@/constants';
import { useCurrentUser } from '@/hooks';
import { track } from '@/utils';

import { OnboardingContext } from '../context';

import { ZapTypes } from '@/types/zapier';

const ScheduleSession = () => {
  const { user, refetchUser } = useCurrentUser();
  const { nextStep } = useContext(OnboardingContext);

  useEffect(() => {
    track('ob_schedule');
  }, []);

  const trackSchedule = async () => {
    try {
      track('ob_scheduledteam_cont');

      const now = new Date();
      now.setHours(ZERO, ZERO, ZERO, ZERO);
      const utc = new Date(
        now.getTime() - now.getTimezoneOffset() * MILLISECONDS_IN_MINUTE,
      );

      await updateUserProfile(user.id, {
        scheduleMatchCall: utc.toISOString(),
      });
      await refetchUser();
      const dataUrl = `/silent/?email=${user.email}`;
      await notifySlack(
        ZapTypes.USER_SCHEDULED_MATCHING_CALL_WITH_MENTO,
        dataUrl,
      );
    } catch (e) {
      console.error(e);
    }
  };

  useCalendlyEventListener({
    onEventScheduled: async (e) => {
      e.stopImmediatePropagation();
      e.preventDefault();

      await trackSchedule();
      nextStep();
    },
  });

  return (
    <div className="flex-1 pt-16 md:pt-0">
      <InlineWidget
        prefill={{
          email: user.email || '',
          name: user.fullName,
        }}
        styles={{ height: '800px' }}
        url={SCHEDULE_SESSION_URL}
      />
    </div>
  );
};

export default ScheduleSession;
