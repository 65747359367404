import {
  useConfirmMessageThreadMessage,
  useGeMessageThreadMessages,
} from '@/hooks';
import { MESSAGE_TYPES, MessageRole } from '@/types';
import { getMessageType } from '@/utils';

import Bubble from './Bubble';
import ListSummary from './ListSummary';
import MultiselectSummary from './MultiselectSummary';
import SelectSummary from './SelectSummary';
import TextSummary from './TextSummary';
import MessageExtendable from './_MessageExtendable';

import type { SelectSummaryProps } from './SelectSummary';
import type { MessageThreadMessage, User } from '@/types';
import type { FC } from 'react';

type MessageProps = {
  currentUser?: User;
  message: MessageThreadMessage;
};

const DefaultMessage: FC<SelectSummaryProps> = ({ children }) => children;

const Message: FC<MessageProps> = ({ currentUser, message }) => {
  const { refetch } = useGeMessageThreadMessages({
    messageThreadId: message.messageThreadId,
  });
  const confirmMessageThreadMessage = useConfirmMessageThreadMessage();

  const onConfirm = (
    confirmed: boolean,
    messageId: string,
    buttonLabel: string,
  ) => {
    confirmMessageThreadMessage.mutate(
      {
        confirmed,
        content: buttonLabel,
        messageId,
      },
      {
        onSuccess: () => refetch(),
      },
    );
  };
  const messageType = getMessageType(message);
  let Component;

  switch (messageType) {
    case MESSAGE_TYPES.TEXT:
      Component = TextSummary;
      break;
    case MESSAGE_TYPES.LIST:
      Component = ListSummary;
      break;
    case MESSAGE_TYPES.RADIO:
      Component = SelectSummary;
      break;
    case MESSAGE_TYPES.SELECT:
      Component = MultiselectSummary;
      break;
    default:
      Component = DefaultMessage;
      break;
  }

  return (
    <div className="mx-auto max-w-full md:max-w-156">
      <Bubble
        currentUser={currentUser}
        isUser={message.role !== MessageRole.ASSISTANT}
        message={message}
      />
      <Component message={message} onConfirm={onConfirm} />
      {message.extendable.title && (
        <MessageExtendable extendable={message.extendable} />
      )}
    </div>
  );
};

export default Message;
