import { InputAdornment } from '@mui/material';
import { styled } from '@mui/material/styles';
import { forwardRef, useState } from 'react';

import Button from '../Button';
import Icon from '../Icon';
import IconButton from '../IconButton';
import TextField from '../TextField';

import type { TextFieldProps } from '../TextField';

interface TextFieldWithButtonProps {
  buttonIcon?: string;
  buttonText?: string;
  className?: string;
  disabled?: boolean;
  label?: string;
  maxRows?: number;
  multiline?: boolean;
  onSubmit: (value: string) => void;
  placeholder: string;
}

/* eslint-disable sort-keys -- This object is ordered by hierarchy rather than alphabetically. */
const StyledTextField = styled(TextField)<TextFieldProps>(({ theme }) => ({
  background: theme.palette.background.default,
  borderRadius: theme.shape.borderRadius,

  '.MuiOutlinedInput-notchedOutline': {
    border: 0,
    boxShadow: theme.shadows[1],
  },
}));
/* eslint-enable sort-keys */

const TextFieldWithButton = forwardRef<
  HTMLInputElement,
  TextFieldWithButtonProps
>(
  (
    {
      buttonIcon = '',
      buttonText,
      disabled = false,
      className = 'w-full',
      multiline = true,
      onSubmit,
      placeholder,
      label,
      maxRows = 4,
    },
    ref,
  ) => {
    const [value, setValue] = useState('');

    const submit = () => {
      if (!value) {
        return;
      }
      onSubmit(value);
      setValue('');
    };

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setValue(event.target.value);
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
      if (disabled) {
        return;
      }

      if (event.key === 'Enter' && !event.shiftKey) {
        submit();
        event.preventDefault();
      }
    };

    const button = buttonIcon.length ? (
      <IconButton
        className="ml-3"
        color="primary"
        disabled={disabled || !value}
        variant="contained"
        onClick={submit}
      >
        <Icon>{buttonIcon}</Icon>
      </IconButton>
    ) : (
      <Button
        className="ml-2"
        color="primary"
        disabled={disabled}
        variant="contained"
        onClick={submit}
      >
        {buttonText}
      </Button>
    );

    // TODO: Check how to better use bodylg here, MUI should expose it some way
    return (
      <StyledTextField
        className={className}
        data-testid="TextFieldWithButton"
        disabled={disabled}
        InputProps={{
          endAdornment: (
            <InputAdornment className="mb-3.5 mr-0 self-end" position="start">
              {button}
            </InputAdornment>
          ),
          style: {
            fontSize: '19px',
            fontWeight: 400,
            letterSpacing: '0px',
            lineHeight: 1.45,
          },
        }}
        inputRef={ref}
        label={label}
        maxRows={maxRows}
        multiline={multiline}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onKeyDown={handleKeyDown}
      />
    );
  },
);
TextFieldWithButton.displayName = 'TextFieldWithButton';

export default TextFieldWithButton;
export type { TextFieldWithButtonProps };
