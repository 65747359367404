import { useCurrentUser, useGetMessageThreads } from '@/hooks';

import type { MessageThread } from '@/types';

import WelcomeChat from '@/components/Onboarding/Welcome/Chat';
import WelcomeLoading from '@/components/Onboarding/Welcome/Loading';
import WelcomeMessage from '@/components/Onboarding/Welcome/Message';

const Welcome = () => {
  const { user } = useCurrentUser();
  const { data: messageThreads, isLoading } = useGetMessageThreads({
    title: 'Onboarding',
    userId: user.id,
  });
  let messageThread: MessageThread | undefined;

  // Loading State
  if (isLoading) {
    return <WelcomeLoading />;
  }

  // Finding onboarding thread
  // Due to how data is structured in the API response, this
  // is a bit convoluted. Basically, we're searching for the first
  // date returned in the API response and making sure it's an
  // onboarding thread.
  const dates = Object.keys(messageThreads?.items ?? {});

  dates.find((date: string) => {
    /* @ts-ignore */
    const items: MessageThread[] = messageThreads?.items[date];
    const onboardingThread = items.find(({ title }) => title === 'Onboarding');

    messageThread = onboardingThread;

    return onboardingThread;
  });

  // If no onboarding thread was found
  if (!messageThread) {
    return <WelcomeMessage />;
  }

  // Render thread
  return <WelcomeChat messageThreadId={messageThread.id} />;
};
export default Welcome;
