import clsx from 'clsx';
import { useEffect, useRef, useState } from 'react';

import { ONE } from '@/constants';
import {
  getOrderedMessages,
  useCurrentUser,
  useGeMessageThreadMessages,
} from '@/hooks';
import { MESSAGE_TYPES, MessageRole } from '@/types';
import { CircularProgress } from '@/ui';
import { getMessageType, sleep, track } from '@/utils';

import CreateMessageForm from './CreateMessageForm';
import Message from './Message';
import ThreadEnded from './ThreadEnded';

import type { MessageThreadMessage } from '@/types';
import type { FC } from 'react';

const Thread: FC<{
  isOnboarding?: boolean;
  messageThreadId: string;
}> = ({ isOnboarding, messageThreadId }) => {
  const [latestSentMessage, setLatestSentMessage] = useState('');

  const chatParent = useRef<HTMLDivElement>(null);
  const topOfChat = useRef<HTMLDivElement>(null);
  const hasScrolledOnInitialPage = useRef(false);

  const { user: currentUser } = useCurrentUser();
  const {
    data,
    hasNextPage,
    fetchNextPage,
    isLoading: isLoadingMessages,
    isRefetching,
  } = useGeMessageThreadMessages(
    {
      messageThreadId,
    },
    {
      onSettled: () => {
        setLatestSentMessage('');
      },
    },
  );

  const beginingOfChat = !data?.pages[data.pages.length - ONE]?.hasNextPage;

  const loadPreviousMessages = async () => {
    const domNode = chatParent.current;

    if (!domNode) {
      return;
    }
    const oldHeight = domNode.scrollHeight;
    await fetchNextPage();
    await sleep(ONE); // Await for render to happen

    domNode.scrollTop = domNode.scrollHeight - oldHeight;
  };

  const allItems: MessageThreadMessage[] = getOrderedMessages(
    data?.pages || [],
  );
  const isEnded = !!data?.pages[0]?.items[0]?.messageThreadEnded;
  const isLastMessageFromUser =
    data?.pages[0]?.items[0]?.role === MessageRole.USER;

  // https://css-tricks.com/books/greatest-css-tricks/pin-scrolling-to-bottom/
  // we need to trigger a manual scroll in order to make auto scroll to bottom work with css
  // We only do this on the first page loaded.
  useEffect(() => {
    const domNode = chatParent.current;
    if (domNode && data?.pages && data.pages.length <= ONE) {
      domNode.scrollTop = domNode.scrollHeight;
      hasScrolledOnInitialPage.current = true;
    }
  }, [data]);

  useEffect(() => {
    if (!topOfChat.current) {
      return undefined;
    }
    const observer = new IntersectionObserver(([entry]) => {
      if (!entry) {
        return;
      }
      if (entry.isIntersecting && hasNextPage) {
        loadPreviousMessages();
      }
    });

    observer.observe(topOfChat.current);

    return () => observer.disconnect();
  }, [topOfChat, hasNextPage]);

  useEffect(() => {
    const lastMessage = allItems.at(-ONE);
    // If last message has a function ID AND it has not been
    // confirmed, we consider this to be the first display.
    // We want to track these.
    if (lastMessage && lastMessage.functionId && !lastMessage.confirmed) {
      track('Structured Data/stage displayed', {
        stage: lastMessage.title,
        type: getMessageType(lastMessage),
      });
    }
  }, [isRefetching, data?.pages[0]]);

  return (
    <div
      className={clsx('flex w-full justify-center', {
        'h-[calc(100%-65px)]': isOnboarding,
        'h-full': !isOnboarding,
      })}
    >
      {isOnboarding && (
        <div className="to-sunny-0 absolute flex w-full justify-between bg-gradient-to-b from-sunny-100 px-10 py-4" />
      )}

      <div className="flex w-full flex-col">
        {isLoadingMessages && (
          <div className="mx-auto max-w-full py-11 md:max-w-156">
            <CircularProgress />
          </div>
        )}
        {!isLoadingMessages && (
          <div
            ref={chatParent}
            className="scroller mb-5 overflow-y-auto overflow-x-hidden px-5 md:px-20"
          >
            {beginingOfChat && <div className="h-28 w-full" />}
            {!beginingOfChat && (
              <div
                ref={topOfChat}
                className="flex items-center justify-center py-5"
              >
                <CircularProgress />
              </div>
            )}
            {allItems.map((message) => (
              <Message
                key={message.id}
                currentUser={currentUser}
                message={message}
              />
            ))}
            {!!latestSentMessage.length && (
              <div className="mx-auto max-w-full md:max-w-156">
                <Message
                  message={{
                    content: latestSentMessage,
                    extendable: {},
                    id: '000000',
                    messageThreadId,
                    messageType: MESSAGE_TYPES.MESSAGE,
                    role: MessageRole.USER,
                    sortKey: new Date().getTime() / 1000, // seconds since epoch, as a placeholder
                  }}
                />
              </div>
            )}
            {(isLastMessageFromUser || !!latestSentMessage.length) && (
              <div className="mx-auto max-w-full md:max-w-144">
                <CircularProgress
                  className="float-right"
                  color="success"
                  size={16}
                />
              </div>
            )}
            <div className="anchor" />
          </div>
        )}

        {isOnboarding && isEnded ? (
          <ThreadEnded messageThreadId={messageThreadId} />
        ) : (
          <CreateMessageForm
            isEnded={isEnded}
            messageThreadId={messageThreadId}
            onCreateMessage={setLatestSentMessage}
          />
        )}
      </div>
    </div>
  );
};

export default Thread;
