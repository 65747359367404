import { getTimeZones } from '@vvo/tzdb';
import { format } from 'date-fns';
import { useContext } from 'react';

import { AppContext } from '@/context';
import { useCurrentUser } from '@/hooks';
import { type User } from '@/types';
import {
  Button,
  Card,
  CardContent,
  Chip,
  Icon,
  IconButton,
  Typography,
} from '@/ui';

import type { FC } from 'react';

interface InformationProps {
  client: User;
  hideActions?: boolean;
  onProfile?: () => void;
}

const Information: FC<InformationProps> = ({
  client,
  hideActions = false,
  onProfile,
}) => {
  const chatContext = useContext(AppContext);
  const { isUserLoading, user } = useCurrentUser();
  const isCoachAdmin = !isUserLoading && user.superAdmin;

  const linkedInUrl = client.profile?.linkedIn || '';

  const handleMessageClick = () => {
    chatContext.setChatId(client.cometChatId);
  };

  const handleLinkedInClick = () => {
    window.open(linkedInUrl, '_blank');
  };

  const timezone = client.profile?.timezone;

  let timeZone = 'Timezone not set';
  if (timezone) {
    const t = getTimeZones().find(
      (tz) => timezone === tz.name || tz.group.includes(timezone || ''),
    );

    if (t) {
      const [time] = t.currentTimeFormat.split(' ');
      timeZone = `(GMT${time}) ${t.mainCities[0]}`;
    }
  }

  return (
    <Card>
      <CardContent className="flex w-full flex-col">
        <Typography className="mb-2 flex justify-between" variant="subtitle1">
          Member Details{' '}
          {!client.isActive && <Chip color="error" label="Member Inactive" />}
        </Typography>
        <div className="flex flex-col gap-1.5">
          <div className="flex items-center gap-2">
            <Icon className="text-sage-500" fontSize="small">
              account_circle
            </Icon>
            <Typography
              className="break-after-all text-cement-400"
              variant="body2"
            >
              {client.email}
            </Typography>
          </div>
          {isCoachAdmin && (
            <div className="flex items-center gap-2">
              <Icon className="text-sage-500" fontSize="small">
                note
              </Icon>
              <Typography
                className="break-after-all text-cement-400"
                variant="body2"
              >
                Documents email: {client.documentsEmail ?? 'No email'}
              </Typography>
            </div>
          )}
          {client.profile?.createdAt && (
            <div className="flex items-center gap-2">
              <Icon className="text-sage-500" fontSize="small">
                device_reset
              </Icon>
              <Typography
                className="break-after-all text-cement-400"
                variant="body2"
              >
                Joined {format(new Date(client.profile.createdAt), 'MMM d')}
              </Typography>
            </div>
          )}
          {client.lastLogin && (
            <div className="flex items-center gap-2">
              <Icon className="text-sage-500" fontSize="small">
                record_voice_over
              </Icon>
              <Typography
                className="break-after-all text-cement-400"
                variant="body2"
              >
                Last online {format(new Date(client.lastLogin), 'MMM d')}
              </Typography>
            </div>
          )}
          {client.profile?.location && (
            <div className="flex items-center gap-2">
              <Icon className="text-sage-500" fontSize="small">
                my_location
              </Icon>
              <Typography
                className="break-after-all text-cement-400"
                variant="caption"
              >
                {client.profile.location}
              </Typography>
            </div>
          )}
          <div className="flex items-center gap-2">
            <Icon className="text-sage-500" fontSize="small">
              schedule
            </Icon>
            <Typography
              className="break-after-all text-cement-400"
              variant="body2"
            >
              {timeZone}
            </Typography>
          </div>
          {isCoachAdmin && (
            <>
              <div className="flex items-center gap-2">
                <Icon className="text-sage-500" fontSize="small">
                  assignment
                </Icon>
                <Typography
                  className="break-after-all text-cement-400"
                  variant="body2"
                >
                  Matching preference:&nbsp;
                  {client.profile?.matchPreference ?? 'Not Selected'}
                </Typography>
              </div>
              <div className="flex items-center gap-2">
                <Icon className="text-sage-500" fontSize="small">
                  article
                </Icon>
                <Typography
                  className="break-after-all text-cement-400"
                  variant="body2"
                >
                  Documents folder:&nbsp;
                  {client.folderId ? (
                    <a
                      href={`https://drive.google.com/drive/u/0/folders/${client.folderId}`}
                      rel="noreferrer"
                      target="_blank"
                    >
                      Folder Link
                    </a>
                  ) : (
                    'Not folder id'
                  )}
                </Typography>
              </div>
            </>
          )}
        </div>
        {!hideActions && (
          <div className="mt-3 flex w-full gap-3">
            {linkedInUrl && (
              <IconButton variant="outlined" onClick={handleLinkedInClick}>
                <Icon>linkedin</Icon>
              </IconButton>
            )}
            <IconButton variant="outlined" onClick={onProfile}>
              <Icon>person</Icon>
            </IconButton>
            <Button
              className="flex-1"
              variant="contained"
              onClick={handleMessageClick}
            >
              Message
            </Button>
          </div>
        )}
      </CardContent>
    </Card>
  );
};

export default Information;
