import { useQueryClient } from '@tanstack/react-query';
import { clsx } from 'clsx';

import { QUERY_KEY_MESSAGE_THREADS } from '@/constants';
import { useCreateMessageThread, useCurrentUser } from '@/hooks';
import { WORKFLOW_CATEGORY } from '@/types';
import { Button, Icon, Typography } from '@/ui';

const Message = () => {
  const { user, isUserLoading } = useCurrentUser();
  const createMessageThread = useCreateMessageThread();
  const queryClient = useQueryClient();

  const iconWrapperClasses = clsx(
    'relative mb-8 flex h-20 w-20 items-center justify-center text-5xl text-white',
  );
  const gradientClasses = clsx(
    'absolute z-0 h-full w-full rounded-full bg-gray-700 bg-gradient-to-br from-poppy-500 to-bloom-500 opacity-95 blur-lg',
  );
  const iconClasses = clsx('z-10');

  const onClick = () => {
    createMessageThread.mutate(
      {
        content: "I'm getting started with Mento!",
        workflow: WORKFLOW_CATEGORY.ONBOARDING,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries({
            queryKey: [QUERY_KEY_MESSAGE_THREADS],
          });
        },
      },
    );
  };

  if (isUserLoading) {
    return null;
  }

  return (
    <section className="mx-auto flex h-screen flex-col justify-between p-5 md:w-150">
      <div>{/* Empty div to vertically center the <header> element. */}</div>
      <header>
        <span className={iconWrapperClasses}>
          <span className={gradientClasses} />
          <Icon className={iconClasses} fontSize="inherit">
            magic_button
          </Icon>
        </span>
        <Typography className="mb-6" variant="h3">
          Welcome, {user.firstName}!
        </Typography>
        <Typography className="mb-2" component="p" variant="bodylg">
          We're thrilled to be a part of your career yourney.
        </Typography>
        <Typography component="p" variant="bodylg">
          I'm a Mento AI assistant trained by the Mento team. My goal is to help
          you prepare for your career growth journey.
        </Typography>
      </header>
      <div className="flex justify-center">
        <Button
          className="mx-auto w-full min-w-72 md:w-72"
          disabled={createMessageThread.isLoading}
          variant="contained"
          onClick={onClick}
        >
          Continue
        </Button>
      </div>
    </section>
  );
};

export default Message;
