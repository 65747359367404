import { clsx } from 'clsx';
import { useState } from 'react';

import {
  Button,
  FormControlLabel,
  Icon,
  Radio,
  RadioGroup,
  Typography,
} from '@/ui';

import SummaryBase from './_SummaryBase';

import type { SummaryBaseProps } from './_SummaryBase';
import type { MessageThreadMessageItem } from '@/types';
import type { ChangeEvent, FC } from 'react';

export type SelectSummaryProps = SummaryBaseProps & {
  onConfirm: (confirmed: boolean, messageId: string, label: string) => void;
};

const SelectSummary: FC<SelectSummaryProps> = ({
  className,
  negativeLean,
  onConfirm,
  message,
}) => {
  const [selected, setSelected] = useState<string | null>(null);
  const items = JSON.parse(message.items ?? '[]') as MessageThreadMessageItem[];

  if (!items.length) {
    return null;
  }

  const messageDeclined = message.confirmed === false; // message.confirmed can also be null, which means not answered

  const classes = clsx('list-summary', className);
  const titleWrapperClasses = clsx(
    'flex flex-col items-start justify-start gap-6 md:flex-row',
    {
      'text-cement-300': messageDeclined,
    },
  );
  const iconClasses = clsx(
    'inline-flex items-center justify-center rounded-full p-2 text-white',
    {
      [`bg-${message.color}-500`]: !messageDeclined,
      'bg-cement-300': messageDeclined,
    },
  );
  const listItemClasses = clsx(
    'multiselect-checkbox-wrapper cursor-pointer rounded-xl border-0 border-b border-solid border-b-sage-200 py-1 last:border-b-0 hover:bg-sage-300',
  );

  const handleChange = ({ target }: ChangeEvent<HTMLInputElement>) =>
    setSelected(target.value);

  const handleConfirm = () => {
    if (!selected) {
      return;
    }
    onConfirm(true, message.id, selected);
  };

  return (
    <>
      <SummaryBase
        className={classes}
        message={message}
        negativeLean={negativeLean}
      >
        <div className={titleWrapperClasses}>
          <span className={iconClasses}>
            <Icon fontSize="medium">{message.icon as string}</Icon>
          </span>
          <div>
            <Typography className="hidden md:block" variant="h5">
              {message.description ?? message.title}
            </Typography>
            <Typography className="block md:hidden" variant="subtitle1">
              {message.description ?? message.title}
            </Typography>
          </div>
        </div>
        <RadioGroup
          className="md:ml-16"
          name={`${message.id}-value`}
          onChange={handleChange}
        >
          {items.map(({ option }) => (
            <FormControlLabel
              key={option}
              className={listItemClasses}
              control={<Radio />}
              disabled={!!message.confirmed}
              label={option}
              value={option}
            />
          ))}
        </RadioGroup>
      </SummaryBase>
      {!message.confirmed && (
        <div className="buttons mb-10 flex flex-wrap items-center justify-center gap-4 pt-8">
          <Button
            color="primary"
            disabled={!selected}
            variant="contained"
            onClick={handleConfirm}
          >
            Continue
          </Button>
        </div>
      )}
    </>
  );
};

export default SelectSummary;
