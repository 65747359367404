import { useFlagBag } from '@happykit/flags/context';
import clsx from 'clsx';
import { useEffect, useMemo, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import { ZERO } from '@/constants';
import { useCurrentUser } from '@/hooks';

import type { FeatureFlags } from '@/types';

import GetStartedNav from '@/components/GetStarted/Navigation';
import { GetStartedContext } from '@/components/GetStarted/context';

// Steps in order
const STEPS = [
  'about',
  'dream-job',
  'career-goal',
  'style',
  'membership-select',
  'membership-paid',
  'get-coach',
  'schedule-session',
];

const GetStartedLayout = () => {
  const { flags } = useFlagBag<FeatureFlags>();
  const { user, isUserLoading } = useCurrentUser();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const [currentStep, setCurrentStep] = useState(ZERO);

  useEffect(() => {
    const subPath = pathname.replace('/get-started/', '');
    const index = STEPS.indexOf(subPath);

    setCurrentStep(index >= ZERO ? index : ZERO);
  }, [pathname]);

  useEffect(() => {
    if (flags?.new_onboarding) {
      navigate('/onboarding');
    }
  }, [flags]);

  const nextStep = (skip = 1, shouldReplace = false) => {
    let nextPath = STEPS[currentStep + skip] ?? '/m';

    // Skip membership if business paid
    // TODO: ADD business status and payment status
    if (nextPath === 'membership-select' && user.isBusinessPaid) {
      nextPath = 'get-coach';
    }

    navigate(nextPath, { replace: shouldReplace });
  };

  const context = useMemo(
    () => ({
      currentStep,
      nextStep,
    }),
    // we don't need to update when nextStep is redefined, because all the
    // variables in the closure will trigger an update
    [currentStep],
  );

  const isMembershipSelect = pathname.startsWith(
    '/get-started/membership-select',
  );

  // Checking for userId to avoid flickers on refetch
  // TODO: Remove once we have fixed the issue with refetch on focus
  if (isUserLoading && !user.id) return null;

  return (
    <main
      className={clsx('flex h-screen flex-1 flex-col overflow-auto', {
        'bg-sunny-100': !isMembershipSelect,
        'bg-sunny-200': isMembershipSelect,
      })}
    >
      <GetStartedContext.Provider value={context}>
        <GetStartedNav />
        <Outlet />
      </GetStartedContext.Provider>
    </main>
  );
};

export default GetStartedLayout;
