import MuiBreadcrumbs from '@mui/material/Breadcrumbs';
import { styled } from '@mui/material/styles';
import { clsx } from 'clsx';

import type { BreadcrumbsProps } from '@mui/material/Breadcrumbs';
import type { FC } from 'react';

const StyledBreadcrumbs = styled(MuiBreadcrumbs)<BreadcrumbsProps>(
  ({ theme }) => ({
    color: theme.palette.text.disabled,
  }),
);

const Breadcrumbs: FC<BreadcrumbsProps> = ({ className, ...rest }) => {
  const classes = clsx('mnt-breadcrumbs', className);

  return <StyledBreadcrumbs {...rest} className={classes} />;
};
Breadcrumbs.displayName = 'Breadcrumbs';

export default Breadcrumbs;
export type { BreadcrumbsProps };
