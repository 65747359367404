import { useEffect, useRef } from 'react';

import {
  useCreateMessageThreadMessage,
  useGeMessageThreadMessages,
} from '@/hooks';
import { MessageRole } from '@/types';
import { TextFieldWithButton, Typography } from '@/ui';

import type { FC } from 'react';

interface CreateMessageFormProps {
  isEnded: boolean;
  messageThreadId: string;
  onCreateMessage: (content: string) => void;
}

const CreateMessageForm: FC<CreateMessageFormProps> = ({
  isEnded,
  messageThreadId,
  onCreateMessage,
}) => {
  const createMessageThreadMessage = useCreateMessageThreadMessage();
  const {
    data,
    isLoading: isLoadingMessages,
    isRefetching,
    refetch,
  } = useGeMessageThreadMessages({
    messageThreadId,
  });

  const inputRef = useRef<HTMLInputElement>(null);

  const isFunction = !!data?.pages[0]?.items[0]?.functionId;
  const isLastMessageFromUser =
    data?.pages[0]?.items[0]?.role === MessageRole.USER;
  const isDisabled =
    createMessageThreadMessage.isLoading ||
    isLoadingMessages ||
    isRefetching ||
    isEnded ||
    isFunction ||
    isLastMessageFromUser;

  const onSubmit = (content: string) => {
    // add the message to the UI first cause refetch is slow
    onCreateMessage(content);

    createMessageThreadMessage.mutate(
      {
        content,
        messageThreadId,
      },
      {
        onSuccess: () => refetch(),
      },
    );
  };

  useEffect(() => {
    if (!inputRef.current) {
      return;
    }
    if (!createMessageThreadMessage.isLoading || !isRefetching) {
      inputRef.current.focus();
    }
  }, [createMessageThreadMessage.isLoading, isRefetching]);

  return (
    <div className="mx-auto mt-auto max-w-full md:max-w-156">
      <div className="mb-8 mt-auto justify-self-end align-bottom">
        <TextFieldWithButton
          ref={inputRef}
          buttonIcon="arrow_upward"
          disabled={isDisabled}
          placeholder="Talk with Mento"
          onSubmit={onSubmit}
        />
        <Typography
          className="inline-block w-full pt-5 text-center text-cement-400"
          variant="caption"
        >
          Mento may make mistakes, please don't rely on its information.
        </Typography>
      </div>
    </div>
  );
};

export default CreateMessageForm;
