import { clsx } from 'clsx';
import { useContext } from 'react';

import { Breadcrumbs, Icon, Typography } from '@/ui';

import { OnboardingContext } from './context';

import type { FC } from 'react';

const Header: FC = () => {
  const { currentStep } = useContext(OnboardingContext);

  const steps = [
    '1. Introduce yourself',
    '2. Your career goals',
    '3. Get the right coach',
  ];

  return (
    <header className="w-full items-center justify-between md:flex">
      <img
        alt="logo"
        height="59"
        src="https://mento-space.nyc3.digitaloceanspaces.com/logo.svg"
        width="157"
      />

      <Breadcrumbs
        aria-label="breadcrumb"
        className="hidden md:block"
        separator=" "
      >
        {steps.map((step, index) => (
          <Typography
            key={step}
            className={clsx({
              'md:ml-12': index !== 0,
              'opacity-60': currentStep > index,
              'text-sage-500': index <= currentStep,
            })}
            variant="subtitle1"
          >
            {step}
            {currentStep > index && (
              <Icon className="ml-2 align-middle">check</Icon>
            )}
          </Typography>
        ))}
      </Breadcrumbs>
    </header>
  );
};

export default Header;
