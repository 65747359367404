import { Navigate } from 'react-router-dom';

import Home from '@/components/Home';
import Layout from '@/components/Layouts';
import CompanyLayout from '@/components/Layouts/Company';
import GetStartedLayout from '@/components/Layouts/GetStarted';
import ManageLayout from '@/components/Layouts/Manage';
import MemberLayout from '@/components/Layouts/Member';
import OnboardingLayout from '@/components/Layouts/Onboarding';
import MemberRedirect from '@/components/MemberRedirect';
import OnboardingPaid from '@/components/Onboarding/OboardingPaid';
import Authenticate from '@/pages/Authenticate';
import CoachAIPage from '@/pages/CoachAI';
import CoachAISessionPage from '@/pages/CoachAI/session';
import Documents from '@/pages/Documents';
import ExternalSessionRedirect from '@/pages/ExternalSessionRedirect';
import About from '@/pages/GetStarted/about';
import CareerGoal from '@/pages/GetStarted/careerGoal';
import CoachingStyle from '@/pages/GetStarted/coachingStyle';
import DreamJob from '@/pages/GetStarted/dreamJob';
import GetCoach from '@/pages/GetStarted/getCoach';
import MembershipPaid from '@/pages/GetStarted/membershipPaid';
import MembershipSelect from '@/pages/GetStarted/membershipSelect';
import ScheduleSession from '@/pages/GetStarted/scheduleSession';
import Growth from '@/pages/Growth';
import Completed from '@/pages/Growth/Completed';
import Milestone from '@/pages/Growth/Milestone';
import Insights from '@/pages/Insights';
import Survey from '@/pages/Insights/Survey';
import Responses from '@/pages/Insights/Survey/Responses';
import Manage from '@/pages/Manage';
import Assistant from '@/pages/Manage/Assistant';
import Assistants from '@/pages/Manage/Assistants';
import Cards from '@/pages/Manage/Cards';
import CoachAdminProfile from '@/pages/Manage/Coach';
import CoachAiPlayground from '@/pages/Manage/CoachAiPlayground';
import CoachAiThread from '@/pages/Manage/CoachAiThread';
import Coaches from '@/pages/Manage/Coaches';
import Companies from '@/pages/Manage/Companies';
import Company from '@/pages/Manage/Company';
import Function from '@/pages/Manage/Function';
import FunctionDisplay from '@/pages/Manage/FunctionDisplay';
import Notifications from '@/pages/Manage/Notifications';
import Member from '@/pages/Member';
import MemberCoaches from '@/pages/Member/Coaches';
import PrivateNotes from '@/pages/Member/PrivateNotes';
import ClientProfile from '@/pages/Member/Profile';
import Members from '@/pages/Members';
import MembershipExpired from '@/pages/MembershipExpired';
import NotFound from '@/pages/NotFound';
import OnboardingAbout from '@/pages/Onboarding/About';
import OnboardingMatch from '@/pages/Onboarding/Match';
import OnboardingWelcome from '@/pages/Onboarding/Welcome';
import Profile from '@/pages/Profile';
import Reset from '@/pages/Reset';
import Root from '@/pages/Root';
import Session from '@/pages/Session';
import PastSessions from '@/pages/Sessions/Past';
import UpcomingSessions from '@/pages/Sessions/Upcoming';
import SignIn from '@/pages/SignIn';
import Support from '@/pages/Support';
import SurveyRedirect from '@/pages/SurveyRedirect';
import Team from '@/pages/Team';
import TeamMember from '@/pages/TeamMember';
import TeamMemberNotes from '@/pages/TeamMember/Notes';
import TeamMemberOverview from '@/pages/TeamMember/Overview';
import Teams from '@/pages/Teams';
import Templates from '@/pages/Templates';
import Template from '@/pages/Templates/Template';
import Toolkit from '@/pages/Toolkit';
import Welcome from '@/pages/Welcome';

export const AppRoutes = [
  { element: <Root />, index: true },
  { element: <SignIn />, path: 'signin' },
  { element: <Authenticate />, path: 'authenticate' },
  { element: <Reset />, path: 'reset' },
  { element: <SignIn />, path: 'coach/signin' },
  { element: <Authenticate />, path: 'coach/authenticate' },
  { element: <MembershipExpired />, path: '/membership-expired' },
  {
    element: <ExternalSessionRedirect />,
    path: '/sessions/external/:externalSessionId/:action',
  },

  {
    children: [
      { element: <About />, path: 'about' },
      { element: <DreamJob />, path: 'dream-job' },
      { element: <CareerGoal />, path: 'career-goal' },
      { element: <CoachingStyle />, path: 'style' },
      { element: <MembershipSelect />, path: 'membership-select' },
      { element: <MembershipPaid />, path: 'membership-paid' },
      { element: <GetCoach />, path: 'get-coach' },
      { element: <ScheduleSession />, path: 'schedule-session' },
      { element: <Navigate to="/get-started/about" replace />, index: true },
    ],
    element: <GetStartedLayout />,
    path: '/get-started',
  },
  {
    children: [
      { element: <OnboardingAbout />, path: 'about' },
      { element: <OnboardingWelcome />, path: 'welcome' },
      { element: <MembershipSelect />, path: 'membership-select' },
      { element: <OnboardingPaid />, path: 'membership-paid' },
      { element: <OnboardingMatch />, path: 'match' },
      { element: <Navigate to="/onboarding/about" replace />, index: true },
    ],
    element: <OnboardingLayout />,
    path: '/onboarding',
  },
  {
    children: [
      { element: <Home />, index: true },
      { element: <Growth />, path: 'growth' },
      { element: <Growth />, path: 'growth/completedSteps' },
      { element: <Growth />, path: 'growth/:growthPlanId' },
      { element: <Growth />, path: 'growth/:growthPlanId/completedSteps' },
      {
        element: <Milestone />,
        path: 'growth/:growthPlanId/milestone/:milestoneId',
      },
      {
        element: <Milestone />,
        path: 'growth/milestone/:milestoneId',
      },
      { element: <Completed />, path: 'growth/completed' }, // Handle the no id case
      { element: <Completed />, path: 'growth/:growthPlanId/completed' },
      { element: <Insights />, path: 'insights' },
      { element: <Survey />, path: 'insights/:templateId' },
      { element: <Profile isClient />, path: 'profile' },
      { element: <Profile />, path: 'profiles-coach/:id' },
      { element: <Welcome />, path: 'welcome' },
      { element: <Support />, path: 'support' },
      { element: <SurveyRedirect />, path: 'survey/:feedbackId' },
      { element: <CoachAIPage />, path: 'workflow' },
      { element: <CoachAISessionPage />, path: 'workflow/:messageThreadId' },
    ],
    element: <Layout />,
    path: '/m',
  },

  {
    children: [
      { element: <Home />, index: true },

      { element: <Members />, path: 'members' },
      {
        children: [
          { element: <Member />, index: true },
          { element: <MemberCoaches />, path: 'coaches' },
          { element: <ClientProfile />, path: 'profile' },
          { element: <Growth />, path: 'growth' },
          { element: <Growth />, path: 'growth/completedSteps' },
          { element: <Milestone />, path: 'growth/milestone/:milestoneId' },
          { element: <Completed />, path: 'growth/completed' },
          { element: <Insights />, path: 'insights' },
          { element: <Survey />, path: 'insights/:templateId' },
          { element: <Responses />, path: 'insights/:templateId/responses' },
          { element: <Documents />, path: 'documents' },
          { element: <Navigate to="upcoming" replace />, path: 'sessions' },
          { element: <UpcomingSessions />, path: 'sessions/upcoming' },
          { element: <PastSessions />, path: 'sessions/past' },
          { element: <Session />, path: 'sessions/:memberSessionId' },
          { element: <PrivateNotes />, path: 'notes' },
        ],
        element: <MemberLayout />,
        handle: { selectedUserId: 'id' },
        path: 'members/:id',
      },

      { element: <MemberRedirect />, path: 'clients/:id' },
      { element: <MemberRedirect />, path: 'clients/:id/*' },

      {
        children: [
          { element: <Templates />, index: true },
          { element: <Template />, path: ':templateId' },
        ],
        path: 'templates',
      },

      {
        children: [
          { element: <Manage />, index: true },
          { element: <Cards />, path: 'cards' },
          { element: <Coaches />, path: 'coaches' },
          { element: <CoachAdminProfile />, path: 'coaches/:id' },
          { element: <Companies />, path: 'companies' },
          {
            children: [
              { element: <Navigate to="current" replace />, index: true },
              { element: <Company />, path: 'current' },
              { element: <Company />, path: 'past' },
            ],
            element: <CompanyLayout />,
            path: 'companies/:companyId',
          },
          { element: <Notifications />, path: 'notifications' },
          {
            element: <Navigate to="/coach/templates" replace />,
            path: 'templates',
          },
          { element: <CoachAiPlayground />, path: 'coach-ai-playground' },
          { element: <CoachAiThread />, path: 'coach-ai-playground/:threadId' },
          { element: <Assistants />, path: 'assistants' },
          { element: <Assistant />, path: 'assistants/:assistantId' },
          { element: <Function />, path: 'assistants/:assistantId/function' },
          {
            element: <Function />,
            path: 'assistants/:assistantId/function/:functionId',
          },
          {
            element: <FunctionDisplay />,
            path: 'assistants/:assistantId/function/:functionId/display',
          },
          {
            element: <FunctionDisplay />,
            path: 'assistants/:assistantId/function/:functionId/display/:displayId',
          },
        ],
        element: <ManageLayout />,
        path: 'manage',
      },

      { element: <Profile />, path: 'profile' },
      { element: <Welcome />, path: 'welcome' },
      { element: <Toolkit />, path: 'toolkit' },
      {
        children: [
          { element: <Documents />, index: true },
          { element: <Templates />, path: 'templates' },
          { element: <Template />, path: 'templates/:templateId' },
        ],
        element: <Layout />,
        path: 'documents',
      },
    ],
    element: <Layout />,
    path: '/coach',
  },

  {
    children: [
      { element: <Navigate to="upcoming" replace />, index: true },
      { element: <UpcomingSessions />, path: 'upcoming' },
      { element: <PastSessions />, path: 'past' },
      { element: <Session />, path: ':sessionId' },
    ],
    element: <Layout />,
    path: 'sessions',
  },

  {
    children: [
      { element: <Documents />, index: true },
      { element: <Templates />, path: 'templates' },
      { element: <Template />, path: 'templates/:templateId' },
    ],
    element: <Layout />,
    path: '/documents',
  },

  {
    children: [
      { element: <Teams />, index: true },
      {
        element: <Team />,
        handle: { selectedTeamId: 'teamId' },
        path: '/teams/:teamId',
      },
      {
        children: [
          { element: <Navigate to="overview" replace />, index: true },
          { element: <Growth />, path: 'growth/:growthPlanId' },
          { element: <Growth />, path: 'growth/:growthPlanId/completedSteps' },
          {
            element: <Milestone />,
            path: 'growth/:growthPlanId/milestone/:milestoneId',
          },
          { element: <Completed />, path: 'growth/:growthPlanId/completed' },
          { element: <TeamMemberOverview />, path: 'overview' },
          { element: <TeamMemberNotes />, path: 'notes' },
        ],

        element: <TeamMember />,
        handle: { selectedTeamId: 'teamId' },
        path: '/teams/:teamId/member/:id',
      },
    ],
    element: <Layout />,
    path: '/teams',
  },
  { element: <NotFound />, path: '*' },
];
