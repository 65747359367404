import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

import type {
  AutocompleteChangeReason,
  FilterOptionsState,
} from '@mui/material';
import type { AutocompleteRenderInputParams } from '@mui/material/Autocomplete';
import type { SyntheticEvent } from 'react';

interface CreatableSelectProps<T> {
  createOnBlur?: boolean;
  error?: boolean;
  helperText?: string;
  label?: string;
  limit?: number;
  multiple?: boolean;
  onChange: (value: T) => void;
  onCreate?: (value: string) => string;
  options: string[];
  placeholder?: string;
  value: T;
}

const CreatableSelect = <T extends string | string[]>({
  error,
  options,
  onChange,
  onCreate = undefined,
  createOnBlur = false,
  multiple = false,
  helperText = '',
  label = '',
  placeholder = '',
  value,
}: CreatableSelectProps<T>) => {
  const filter = createFilterOptions<string>();
  const defaultFilterOptions = (
    ops: string[],
    st: FilterOptionsState<string>,
  ) => {
    const filtered = filter(ops, st);
    const { inputValue } = st;

    // Suggest the creation of a new value
    const isExisting = ops.some((option) => inputValue === option);
    if (inputValue !== '' && !isExisting) {
      filtered.push(`${inputValue}`);
    }

    return filtered;
  };
  const defaultOnCreate = (createValue: string) => createValue;
  const defaultRenderInput = (params: AutocompleteRenderInputParams) => (
    <TextField
      {...params}
      error={error}
      helperText={helperText}
      label={label}
      placeholder={placeholder}
    />
  );

  const createAndChange = (createAndChangeValue: string) => {
    const option = onCreate
      ? onCreate(createAndChangeValue)
      : defaultOnCreate(createAndChangeValue);

    if (multiple && Array.isArray(value)) {
      onChange([...(value as []), option] as T);
    } else {
      onChange(option as T);
    }
  };

  const onPreChange = (
    event: SyntheticEvent<Element, Event>,
    updatedValue: string | string[] | null,
    reason: AutocompleteChangeReason,
  ) => {
    // @ts-ignore value is on event but not in TS?
    const internalValue = event.target.value;
    if (internalValue && reason === 'createOption') {
      createAndChange(internalValue);
    } else if (updatedValue) {
      onChange(updatedValue as T);
    } else {
      onChange(multiple ? ([] as unknown as T) : ('' as T));
    }
  };

  const onBlur = (event: SyntheticEvent<Element, Event>) => {
    if (createOnBlur) {
      // @ts-ignore value is on event but not in TS?
      const internalValue = event.target.value;
      if (internalValue !== value) {
        createAndChange(internalValue);
      }
    }
  };

  return (
    <Autocomplete
      filterOptions={defaultFilterOptions}
      multiple={multiple}
      options={options}
      renderInput={defaultRenderInput}
      value={value}
      forcePopupIcon
      freeSolo
      handleHomeEndKeys
      selectOnFocus
      onBlur={onBlur}
      onChange={onPreChange}
    />
  );
};
CreatableSelect.displayName = 'CreatableSelect';

export default CreatableSelect;
export type { CreatableSelectProps };
