import { MESSAGE_TYPES, MessageRole } from '@/types';

import type { MessageThreadMessage } from '@/types';

export const getMessageType = (
  message: MessageThreadMessage,
): MESSAGE_TYPES => {
  // TODO: Backend is not returning messageType on all instances.
  // MESSAGE, TEXT and LIST seems return undefined always.
  // Once BE returns, this method can be deleted.

  // If messageType exists, just return it.
  if (message.messageType) {
    return message.messageType;
  }

  // Next, we try to figure out the missisg types,
  // First, if the role is user, we're sure this is a MESSAGE.
  if (message.role === MessageRole.USER) {
    return MESSAGE_TYPES.MESSAGE;
  }

  // If there are no items, this seems to be a TEXT
  if (!message.items) {
    return MESSAGE_TYPES.TEXT;
  }

  // If there are items, this seems to be a LIST
  if (message.items) {
    return MESSAGE_TYPES.LIST;
  }

  // If we can't figure it out, just return MESSAGE
  return MESSAGE_TYPES.MESSAGE;
};
