import { useQueryClient } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';

import { QUERY_KEY_MESSAGE_THREADS } from '@/constants';
import { Button } from '@/ui';

import Header from '../Header';

import Thread from './Thread';

import './index.css';

const CoachAISession = () => {
  const navigate = useNavigate();
  const { messageThreadId = '' } = useParams();
  const queryClient = useQueryClient();

  const goBack = () => {
    queryClient.invalidateQueries({ queryKey: [QUERY_KEY_MESSAGE_THREADS] });
    navigate('/m/workflow');
  };

  return (
    <div className="h-full w-full">
      <Header
        backButton={
          <Button color="primary" variant="outlined" onClick={goBack}>
            End session
          </Button>
        }
      />
      <Thread messageThreadId={messageThreadId} />
    </div>
  );
};

export default CoachAISession;
