import { captureException } from '@sentry/react';
import { useContext } from 'react';
import { useController, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router';

import { createFunctionDisplay, updateFunctionDisplay } from '@/api';
import { AppContext } from '@/context';
import {
  AppMessageSeverities,
  FunctionDisplayColors,
  type FunctionDisplay as FunctionDisplayType,
} from '@/types';
import { Button, MenuItem, TextField, Typography } from '@/ui';

import { useGetFunction } from '@/hooks/useGetFunction';
import { useGetFunctionDisplay } from '@/hooks/useGetFunctionDisplay';

const FunctionDisplay = () => {
  const { functionId = '', assistantId = '', displayId = '' } = useParams();
  const {
    data = { color: FunctionDisplayColors.sage } as FunctionDisplayType,
  } = useGetFunctionDisplay(displayId);
  const { refetch } = useGetFunction(functionId);
  const { setAppMessage } = useContext(AppContext);
  const navigate = useNavigate();
  const onBack = () =>
    navigate(`/coach/manage/assistants/${assistantId}/function/${functionId}`);

  const {
    control,
    formState: { isValid, isSubmitting },
    handleSubmit,
  } = useForm({
    defaultValues: {
      color: data.color,
      icon: data.icon || '',
      messageType: data.messageType || '',
      title: data.title || '',
    },
    values: data,
  });

  const { field: color, fieldState: colorState } = useController({
    control,
    name: 'color',
    rules: { required: true },
  });
  const { field: icon, fieldState: iconState } = useController({
    control,
    name: 'icon',
    rules: { required: true },
  });
  const { field: title, fieldState: titleState } = useController({
    control,
    name: 'title',
  });
  const { field: messageType, fieldState: messageTypeState } = useController({
    control,
    name: 'messageType',
  });

  const onSave = () => {
    handleSubmit(async (formData) => {
      try {
        if (displayId === '') {
          await createFunctionDisplay({ ...formData, functionId });
        } else {
          await updateFunctionDisplay({
            ...formData,
            functionId,
          });
        }
        refetch();
        setAppMessage({
          message: 'Changes saved',
          open: true,
          severity: AppMessageSeverities.Success,
        });
      } catch (e) {
        captureException(e);
        setAppMessage({
          message: 'Error saving assistant',
          open: true,
          severity: AppMessageSeverities.Error,
        });
      }
    })();
  };

  return (
    <div className="max-w-screen-sm p-6">
      <div className="flex flex-col gap-4">
        <Typography variant="h4">Function Display</Typography>

        <TextField
          error={colorState.invalid}
          helperText="Required"
          label="Color"
          variant="outlined"
          select
          {...color}
        >
          {Object.keys(FunctionDisplayColors).map((clr: string) => (
            <MenuItem key={clr} value={clr}>
              {clr}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          error={iconState.invalid}
          helperText="Required"
          label="Icon"
          variant="outlined"
          {...icon}
        />
        <TextField
          error={titleState.invalid}
          label="Title"
          variant="outlined"
          {...title}
        />
        <TextField
          error={messageTypeState.invalid}
          label="Message Type"
          variant="outlined"
          {...messageType}
        />
        <div className="flex gap-2">
          <Button
            className="self-baseline"
            color="primary"
            variant="outlined"
            onClick={onBack}
          >
            Back
          </Button>
          <Button
            className="self-baseline"
            color="primary"
            disabled={!isValid}
            loading={isSubmitting}
            variant="contained"
            onClick={onSave}
          >
            Save
          </Button>
        </div>
      </div>
    </div>
  );
};
export default FunctionDisplay;
