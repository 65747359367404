import { useNavigate } from 'react-router';

import { useCurrentUser, useGeMessageThreadMessages } from '@/hooks';
import { Button } from '@/ui';

import type { FC } from 'react';

interface ThreadEndedProps {
  messageThreadId: string;
}

const ThreadEnded: FC<ThreadEndedProps> = ({ messageThreadId }) => {
  const { user } = useCurrentUser();
  const navigate = useNavigate();
  const { data, isLoading, isRefetching } = useGeMessageThreadMessages({
    messageThreadId,
  });
  const isEnded = !!data?.pages[0]?.items[0]?.messageThreadEnded;

  if (isLoading || isRefetching || !isEnded) {
    return null;
  }

  const onClick = () => {
    if (user.isBusinessPaid) {
      navigate('/onboarding/match');
    } else {
      navigate('/onboarding/membership-select');
    }
  };

  return (
    <div className="mx-auto mt-auto max-w-full py-5 md:max-w-156">
      <Button
        className="mx-auto w-full min-w-72 md:w-72"
        variant="contained"
        onClick={onClick}
      >
        Continue
      </Button>
    </div>
  );
};

export default ThreadEnded;
