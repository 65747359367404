import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router';

import { notifySlack, validateSubscription } from '@/api';
import { AppContext } from '@/context';
import { useCurrentUser } from '@/hooks';
import { AppMessageSeverities, UserPaymentStatus } from '@/types';
import { track } from '@/utils';

import { ZapTypes } from '@/types/zapier';

const OnboardingPaid = () => {
  const { user, refetchUser } = useCurrentUser();
  const { setAppMessage } = useContext(AppContext);

  const navigate = useNavigate();

  useEffect(() => {
    track('ob_selectplan_cont');

    if (user.email) {
      (async () => {
        try {
          const userPayment = await validateSubscription(user);

          if (userPayment.status !== UserPaymentStatus.active) {
            navigate('/onboarding/membership-select');
          } else {
            const paidMembershipUrl = `/silent/?email=${user.email}&plan=${
              userPayment.plan?.toLowerCase() ?? 'a'
            } plan`;
            await notifySlack(ZapTypes.USER_PAID_MEMBERSHIP, paidMembershipUrl);
            await refetchUser();
            navigate('/onboarding/match');
          }
        } catch (error) {
          setAppMessage({
            message: 'Something went wrong! Please try again.',
            open: true,
            severity: AppMessageSeverities.Error,
          });
          // wait two seconds before redirecting
          setTimeout(() => navigate('/onboarding/membership-select'), 2000);
        }
      })();
    }
  }, [user.email]);

  return <div className="flex w-screen flex-1 flex-col bg-sunny-200" />;
};

export default OnboardingPaid;
