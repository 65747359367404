import { useContext, useEffect, useState } from 'react';
import { useController, useForm } from 'react-hook-form';

import { updateHubspotContact, updateUser, updateUserProfile } from '@/api';
import { JOB_EXPERIENCE, JOB_TITLES } from '@/constants';
import { useCurrentUser } from '@/hooks';
import { Button, CreatableSelect, MenuItem, TextField, Typography } from '@/ui';
import { alias, track, urlSafeEmail } from '@/utils';

import { OnboardingContext } from './context';

import type { AboutForm } from '@/types';
import type { ChangeEvent } from 'react';

import { notifySlack } from '@/api/zapier';
import { ZapTypes } from '@/types/zapier';

const About = () => {
  const { user, isUserLoading, refetchUser } = useCurrentUser();
  const { nextStep } = useContext(OnboardingContext);
  const [saving, setSaving] = useState(false);

  const {
    handleSubmit,
    control,
    formState: { isValid },
    reset,
  } = useForm<AboutForm>({
    defaultValues: {
      company: '',
      experience: '',
      firstName: '',
      lastName: '',
      linkedIn: '',
      title: '',
    },
    mode: 'onChange',
  });

  const { field: company, fieldState: companyState } = useController({
    control,
    name: 'company',
    rules: { required: true },
  });
  const { field: experience, fieldState: experienceState } = useController({
    control,
    name: 'experience',
    rules: { required: true },
  });
  const { field: firstName, fieldState: firstNameState } = useController({
    control,
    name: 'firstName',
    rules: { required: true },
  });
  const { field: lastName, fieldState: lastNameState } = useController({
    control,
    name: 'lastName',
    rules: { required: true },
  });
  const { field: linkedIn, fieldState: linkedInState } = useController({
    control,
    name: 'linkedIn',
    rules: {
      pattern: {
        message: 'Invalid Linkedin url',
        value: /^https:\/\/(www\.)?linkedin\.com\/in\/[a-zA-Z0-9-]{5,30}\/?$/u,
      },
    },
  });
  const { field: title, fieldState: titleState } = useController({
    control,
    name: 'title',
    rules: { required: true },
  });

  useEffect(() => {
    track('ob_about');
  }, []);

  useEffect(() => {
    if (user.id) {
      reset({
        company: user.currentCompany?.name ?? '',
        experience: user.profile?.experience ?? '',
        firstName: user.firstName.includes('@') ? '' : user.firstName,
        lastName: user.lastName,
        linkedIn: user.profile?.linkedIn ?? '',
        title: user.profile?.title ?? '',
      });
    }
  }, [user]);

  const onNext = async (data: Partial<AboutForm>) => {
    try {
      setSaving(true);
      track('ob_about_cont');

      // Set Mixpanel info
      alias(user.email);

      await Promise.allSettled([
        // TODO: MOVE TO BE
        // First instance of `updateHubspotContact` for a given email will create the contact in Hubspot as long as it is in Production
        // Hubspot expects certain values so we have to send those
        updateHubspotContact(user.email, {
          company: data.company,
          email: user.email,
          experience: data.experience,
          firstname: data.firstName,
          full_name: `${data.firstName} ${data.lastName}`,
          jobtitle: data.title,
          lastname: data.lastName,
          lifecyclestage: 'opportunity',
          linkedIn: data.linkedIn,
        }),
        // Update models
        updateUser(user.id, {
          firstName: data.firstName,
          lastName: data.lastName,
        }),
        updateUserProfile(user.id, {
          company: data.company,
          experience: data.experience,
          linkedIn: data.linkedIn,
          title: data.title,
        }),
      ]);
      await refetchUser();
      // Notify Slack of Quiz Started
      if (user.isBusinessPaid) {
        const businessStartedDataUrl = `/silent?email=${urlSafeEmail(
          user.email,
        )}&company=${data.company}`;

        await notifySlack(
          ZapTypes.BUSINESS_STARTED_QUIZ,
          businessStartedDataUrl,
        );
      } else {
        const dataUrl = `/silent?email=${urlSafeEmail(user.email)}`;
        await notifySlack(ZapTypes.USER_STARTED_QUIZ, dataUrl);
      }
      nextStep();
    } catch (e) {
      console.error(e);
    } finally {
      setSaving(false);
    }
  };

  if (isUserLoading) return null;

  return (
    <form id="create-user" onSubmit={handleSubmit(onNext)}>
      <fieldset className="mx-auto max-w-screen-md border-0 text-center">
        <Typography className="mb-5" variant="h4">
          Before we start, tell us about&nbsp;
          <strong className="bg-gradient-to-br from-poppy-500 to-bloom-500 bg-clip-text text-transparent">
            yourself!
          </strong>
        </Typography>
        <Typography className="mx-auto mb-8 max-w-100" variant="body1">
          This will help us understand your current experience and match you
          with the right coach.
        </Typography>
        <div className="mx-auto flex w-full max-w-118 flex-col gap-6">
          <TextField
            error={firstNameState.invalid}
            helperText={firstNameState.invalid && 'First name is required'}
            label="First name"
            {...firstName}
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              firstName.onChange(event.target.value.trim())
            }
          />
          <TextField
            error={lastNameState.invalid}
            helperText={lastNameState.invalid && 'Last name is required'}
            label="Last name"
            {...lastName}
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              lastName.onChange(event.target.value.trim())
            }
          />
          <CreatableSelect
            error={titleState.invalid}
            helperText={
              titleState.invalid
                ? 'Title is required'
                : 'Select one or type in your own'
            }
            label="Current or recent job title"
            options={JOB_TITLES}
            createOnBlur
            {...title}
            onChange={(value: string) => title.onChange(value.trim())}
          />
          <TextField
            disabled={!!user.currentCompany?.name}
            error={companyState.invalid}
            helperText={companyState.invalid && 'Company is required'}
            label={`Current${
              user.profile?.company ? ' or recent' : ''
            } company`}
            {...company}
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              company.onChange(event.target.value.trim())
            }
          />
          <TextField
            className="text-left"
            error={experienceState.invalid}
            helperText={experienceState.invalid && 'Experience is required'}
            label="Years of experience"
            select
            {...experience}
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              experience.onChange(event.target.value.trim())
            }
          >
            {JOB_EXPERIENCE.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            error={linkedInState.invalid}
            helperText={
              linkedInState.invalid && 'Linkedin profile url is invalid'
            }
            label="Linkedin profile"
            {...linkedIn}
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              linkedIn.onChange(event.target.value.trim())
            }
          />

          <Button
            className="mx-auto w-full min-w-72 md:w-72"
            color="primary"
            disabled={!isValid}
            form="create-user"
            loading={saving}
            type="submit"
            variant="contained"
          >
            Continue
          </Button>
        </div>
      </fieldset>
    </form>
  );
};

export default About;
